import { FC, memo, useEffect, useMemo, useState } from 'react';
import NextImage from 'next/image';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import {
  Partner as UIPartner,
  Text,
  Name,
  CloseButton,
  Icon,
} from './partner.styles';
import { useRouter } from 'next/router';
import { PartnersQueryParams } from '@keaze/web/common/enums';
import { deleteParamFromQueryByName } from '@keaze/web/utils/deleteParamFromQueryByName';

export const Partner: FC = memo(() => {
  const router = useRouter();
  const partnerName = (router.query[PartnersQueryParams.Partner] ||
    router.query[PartnersQueryParams.Source]) as string;
  const isPartnerName = useMemo(() => !!partnerName, [partnerName]);
  const [open, setOpen] = useState<boolean>(isPartnerName);

  useEffect(() => {
    setOpen(isPartnerName);
  }, [isPartnerName]);

  const handleCloseButtonClick = () => {
    const partnerName = (router.query[PartnersQueryParams.Partner] as string)
      ? PartnersQueryParams.Partner
      : PartnersQueryParams.Source;

    setOpen(false);
    deleteParamFromQueryByName({
      router,
      name: partnerName,
      withShallow: true,
    });
  };

  if (open) {
    return (
      <UIPartner container alignItems="center">
        <Container>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Icon>
              <NextImage src="/svg/award.svg" width={24} height={24} />
            </Icon>
            <Text variant="caption">In partnership with </Text>
            <Name variant="caption">{partnerName}</Name>
            <CloseButton onClick={handleCloseButtonClick}>
              <CloseIcon />
            </CloseButton>
          </Grid>
        </Container>
      </UIPartner>
    );
  }

  return null;
});
