import { useCallback, useState } from 'react';

import { ExpectedUseSignInDialog } from './useSignInDialog.types';

type UseSignInDialog = () => ExpectedUseSignInDialog;

export const useSignInDialog: UseSignInDialog = () => {
  const [isSignInDialog, setIsSignInDialog] = useState<boolean>(false);

  const resetSignInDialog = useCallback(() => {
    setIsSignInDialog(false);
  }, [setIsSignInDialog]);

  const toggleSignInDialog = useCallback(() => {
    setIsSignInDialog((prevState) => !prevState);
  }, [setIsSignInDialog]);

  return {
    isSignInDialog,
    setIsSignInDialog,
    resetSignInDialog,
    toggleSignInDialog,
  };
};
