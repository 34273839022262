import { FC, memo, useContext } from 'react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import dynamic from 'next/dynamic';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {
  StyledHeader,
  BackButtonTo,
  LogoLink,
  Content,
  StyledLinks,
  BurgerMenu,
} from './header.styles';
import { Link as LinkType } from '../base.types';
import { useHeader } from './header.hook';
import { StoreContext } from '@keaze/web/store';
import { DrawerProps } from './drawer';

const DynamicDrawer = dynamic<DrawerProps>(
  () => import('./drawer').then((module) => module.Drawer),
  { ssr: false }
);

type RenderDrawerArgs = {
  links: LinkType[];
  open: boolean;
  toggleDrawer: () => void;
};

type RenderDrawer = (args: RenderDrawerArgs) => JSX.Element;

type RenderBackButtonTo = (
  name: string,
  onBackButtonToClick: () => void
) => JSX.Element;

const renderDrawer: RenderDrawer = ({ links, open, toggleDrawer }) => (
  <BurgerMenu>
    <IconButton aria-label="menu" onClick={toggleDrawer}>
      <NextImage
        src="/svg/menu.svg"
        alt="burger menu"
        width={22}
        height={22}
        objectFit="contain"
        priority
      />
    </IconButton>
    <DynamicDrawer links={links} open={open} onClose={toggleDrawer} />
  </BurgerMenu>
);

const renderBackButtonTo: RenderBackButtonTo = (name, onBackButtonToClick) => (
  <BackButtonTo startIcon={<ArrowBackIosIcon />} onClick={onBackButtonToClick}>
    {name}
  </BackButtonTo>
);

export const Header: FC = memo(() => {
  const { links, drawerOpen, toggleDrawer } = useHeader();
  const {
    state: {
      backButtonTo: {
        name,
        isVisible: isVisibleBackButtonTo,
        isHideMenu,
        onClick: onBackButtonToClick,
      },
    },
  } = useContext(StoreContext);
  const isVisibleNav = !isHideMenu;

  return (
    <StyledHeader>
      <Container>
        <Content container justify="space-between">
          <NextLink href="/" prefetch={false} passHref>
            <LogoLink component="a">
              <NextImage
                src="/svg/logo.svg"
                alt="keaze logo"
                width={121}
                height={36}
                quality={90}
                priority
              />
            </LogoLink>
          </NextLink>
          {isVisibleBackButtonTo &&
            renderBackButtonTo(name, onBackButtonToClick)}
          {isVisibleNav && <StyledLinks data={links} />}
          {isVisibleNav &&
            renderDrawer({
              links,
              open: drawerOpen,
              toggleDrawer,
            })}
        </Content>
      </Container>
    </StyledHeader>
  );
});
