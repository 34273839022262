import { request } from '../../api';
import { Methods } from '../../api.types';
import { AuthPasswordRegister } from './authPasswordRegister.types';

const DEFAULT_QUERY = 'passwordless-register';

export const authPasswordRegister: AuthPasswordRegister = async (
  url,
  profile
) =>
  await request({
    query: DEFAULT_QUERY,
    method: Methods.POST,
    data: { url, ...profile },
  });
