import React, { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LoaderProps, LoaderSize } from './Loader.types';
import { Backdrop } from './Loader.styles';

export const Loader: FC<LoaderProps> = ({
  open = false,
  position = 'absolute',
  opacity = 0.5,
  size = LoaderSize.Medium,
  isCardGroup = false,
  className,
}) => (
  <Backdrop
    className={className}
    $position={position}
    $opacity={opacity}
    $isCardGroup={isCardGroup}
    open={open}
  >
    <CircularProgress size={size} color="secondary" />
  </Backdrop>
);
