import { Profile } from '@keaze/web/common/interfaces';

export type ProfileInitialState = {
  profile: Profile;
  loading: boolean;
  error: Error | null;
};

export enum ProfileActionNames {
  Set = 'SET_PROFILE',
  Update = 'UPDATE_PROFILE',
  Loading = 'LOADING_PROFILE',
  Error = 'ERROR_PROFILE',
  Clear = 'CLEAR_PROFILE',
}

export type SetProfileAction = {
  type: ProfileActionNames;
  payload: Profile;
};
export type SetProfile = (profile: Profile) => SetProfileAction;

export type UpdateProfileAction = {
  type: ProfileActionNames;
  payload: Profile;
};
export type UpdateProfile = (profile: Profile) => UpdateProfileAction;

export type ClearProfileAction = {
  type: ProfileActionNames;
  payload?: null;
};
export type ClearProfile = () => ClearProfileAction;

export type LoadingProfileAction = {
  type: ProfileActionNames;
  payload: boolean;
};
export type LoadingProfile = (loading: boolean) => LoadingProfileAction;

export type ErrorProfileAction = {
  type: ProfileActionNames;
  payload: Error;
};
export type ErrorProfile = (error: Error) => ErrorProfileAction;

export type ProfileAction =
  | SetProfileAction
  | UpdateProfileAction
  | ClearProfileAction
  | LoadingProfileAction
  | ErrorProfileAction;
