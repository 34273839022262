type SetABTestPersonalizedSearchPayload =
  | 'banner'
  | 'button'
  | 'landing'
  | null;

export type ABTestInitialState = {
  personalizedSearch: {
    formCallToAction: SetABTestPersonalizedSearchPayload;
  };
};

export enum ABTestActionNames {
  SetPersonalizedSearch = 'SET_AB_TEST_PERSONALIZED_SEARCH',
}

export type SetABTestPersonalizedSearchAction = {
  type: ABTestActionNames;
  payload: SetABTestPersonalizedSearchPayload;
};

export type SetABTestPersonalizedSearch = (
  formCallToAction: SetABTestPersonalizedSearchPayload
) => SetABTestPersonalizedSearchAction;

export type ABTestAction = SetABTestPersonalizedSearchAction;
