export type LoaderProps = {
  open: boolean;
  position?: 'absolute' | 'fixed';
  opacity?: number;
  size?: LoaderSize;
  isCardGroup?: boolean;
  className?: string;
};

export enum LoaderSize {
  Small = 30,
  Medium = 40,
}
