import {
  SetBackButtonToAction,
  ResetBackButtonToAction,
  BackButtonToActionNames,
} from './backButtonTo.types';

type SetBackButtonToArgs = {
  name: string;
  onClick: () => void;
  isHideMenu?: boolean;
};

type SetBackButtonTo = (args: SetBackButtonToArgs) => SetBackButtonToAction;

type ResetBackButtonTo = () => ResetBackButtonToAction;

export const setBackButtonTo: SetBackButtonTo = ({
  name,
  onClick,
  isHideMenu = false,
}) => ({
  type: BackButtonToActionNames.Set,
  payload: {
    isVisible: true,
    isHideMenu,
    name,
    onClick,
  },
});

export const resetBackButtonTo: ResetBackButtonTo = () => ({
  type: BackButtonToActionNames.Reset,
  payload: null,
});
