import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { fade } from '@material-ui/core/styles';

export const Partner = styled(Grid)`
  ${({ theme: { palette } }) => `
    min-height: 48px;
    background-color: ${palette.primary.main};
  `}
`;

export const Text = styled(Typography)`
  ${({ theme: { palette, breakpoints } }) => `
    color: ${palette.common.white};
    font-weight: 500;
    margin: 0 4px 0 5px;
    flex: 0 1 auto;

    ${breakpoints.up('sm')} {
      font-size: 15px;
      line-height: 19px;
      font-weight: 400;
      margin: 0 4px 0 10px;
    }
  `}
`;

export const Name = styled(Typography)`
  ${({ theme: { palette, breakpoints } }) => `
    color: ${palette.common.white};
    font-weight: 700;
    flex: 1 1 auto;

    ${breakpoints.up('sm')} {
      font-size: 15px;
      line-height: 19px;
    }
  `}
`;

export const CloseButton = styled(IconButton)`
  ${({ theme: { palette } }) => `
    padding: 8px;
    color: ${palette.common.white};

    &:hover {
      background-color: ${fade(palette.common.white, 0.04)};
    }
  `}
`;

export const Icon = styled(Box)`
  flex: 0 0 auto;
`;
