import {
  BackButtonToAction,
  BackButtonToActionNames,
  BackButtonToInitialState,
} from './backButtonTo.types';

export const BACK_BUTTON_TO_INITIAL_STATE: BackButtonToInitialState = {
  isVisible: false,
  isHideMenu: false,
  name: '',
  onClick: () => {},
};

export const backButtonToReducer = (
  state: BackButtonToInitialState = BACK_BUTTON_TO_INITIAL_STATE,
  action: BackButtonToAction
) => {
  switch (action.type) {
    case BackButtonToActionNames.Set:
      return {
        ...state,
        ...action.payload,
      };

    case BackButtonToActionNames.Reset:
      return BACK_BUTTON_TO_INITIAL_STATE;

    default:
      return state;
  }
};
