import { NextRouter } from 'next/router';

import { QueryParams } from '@keaze/web/common/enums';
import { Link } from '../../base.types';

type IsActiveLink = (router: NextRouter, link: Link) => boolean;

export const isActiveLink: IsActiveLink = (router, link) => {
  if (typeof link.href === 'string') {
    return router.pathname === link.href;
  }

  const queryParamOccupancyType = router.query[QueryParams.OccupancyType];
  if (queryParamOccupancyType) {
    const generateHrefQuery = link.href.query
      ? link.href.query[QueryParams.OccupancyType]
      : '';

    return queryParamOccupancyType === generateHrefQuery;
  }

  return router.pathname === link.href.pathname;
};
