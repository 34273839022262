import { request } from '../../api';
import { Methods } from '../../api.types';
import { AuthPasswordAccess } from './authPasswordAccess.types';

const DEFAULT_QUERY = 'passwordless-access';

export const authPasswordAccess: AuthPasswordAccess = async (email, url) =>
  await request({
    query: DEFAULT_QUERY,
    method: Methods.POST,
    data: { email, url },
  });
