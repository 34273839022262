import { useContext, useEffect } from 'react';

import { GTMLogin } from '@keaze/web/libs/GTM';
import { StoreContext } from '@keaze/web/store';

export const useGTMLogin = (isAuthenticated: boolean) => {
  const {
    state: {
      profile: { profile },
    },
  } = useContext(StoreContext);

  useEffect(() => {
    if (isAuthenticated && profile.email) {
      GTMLogin(profile.email);
    }
  }, [isAuthenticated, profile]);
};
