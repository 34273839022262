import { useState, useCallback } from 'react';

import { UsePagination } from './usePagination.types';

export const usePagination: UsePagination = () => {
  const [page, setPage] = useState<number>(1);

  const handlePageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  return { page, onPageChange: handlePageChange };
};
