import styled from 'styled-components';
import MUIButton from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { Links } from './links';

export const StyledHeader = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    position: absolute;
    width: 100%;
    background-color: transparent;
    padding: 20px 0;
    flex: 0 0 auto;
    z-index: 9;

    ${breakpoints.up('md')} {
      padding: 25px 0;
    }
  `}
`;

export const BackButtonTo = styled(MUIButton)`
  ${({ theme: { palette, colors, typography, breakpoints } }) => `
    position: absolute;
    top: 50%;
    left: 135px;
    transform: translateY(-50%);
    color: ${palette.primary.main};
    background-color: ${palette.common.white};
    font-size: ${typography.body2.fontSize};
    line-height: ${typography.body2.lineHeight};
    font-weight: ${typography.body2.fontWeight};
    padding: 10px 15px;
    border-radius: 0;

    .MuiButton-startIcon {
      margin-right: 0;

      svg {
        fill: ${colors.blues.blues1};
        opacity: 0.3;
      }
    }

    ${breakpoints.up('md')} {
      left: 150px;
    }
  `}
`;

export const LogoLink = styled(Box)`
  display: flex;
  align-items: center;
`;

export const Content = styled(Grid)`
  position: relative;
`;

export const StyledLinks = styled(Links)`
  ${({ theme: { breakpoints } }) => `
    display: none;

    ${breakpoints.up('lg')} {
      display: flex;
    }
  `}
`;

export const BurgerMenu = styled(Box)`
  ${({ theme: { breakpoints } }) => `

    ${breakpoints.up('lg')} {
      display: none;
    }
  `}
`;
