import {
  QueryParams,
  OccupancyType,
  EnumSearchBasicParams,
} from '@keaze/web/common/enums';
import { SearchBasicParams } from '@keaze/web/common/interfaces';
import {
  GenerateParams,
  GetSearchBasicParams,
} from './getSearchBasicParams.types';

const generateParams: GenerateParams = (params) =>
  params.reduce((acc, { name, value }) => {
    if (value) {
      return {
        ...acc,
        [name]: value,
      };
    }

    return acc;
  }, {} as SearchBasicParams);

export const getSearchBasicParams: GetSearchBasicParams = (query) => {
  const occupancyType =
    (query[QueryParams.OccupancyType] as string) ?? OccupancyType.Sale;
  const location = query[QueryParams.Location] as string;
  const radius = query[QueryParams.Radius] as string;

  return generateParams([
    { name: EnumSearchBasicParams.OccupancyType, value: occupancyType },
    { name: EnumSearchBasicParams.Location, value: location },
    { name: EnumSearchBasicParams.Radius, value: Number(radius) },
  ]);
};
