import { useMutation } from 'react-query';

import {
  authPasswordRegister,
  ExpectedAuthPasswordRegister,
} from '@keaze/web/api/auth/authPasswordRegister';
import { AuthKeys } from '@keaze/web/api/auth/auth.types';
import { Profile } from '@keaze/web/common/interfaces';

type UseAuthPasswordRegisterArgs = {
  url: string;
  profile: Partial<Profile>;
};

export const useAuthPasswordRegister = () =>
  useMutation<ExpectedAuthPasswordRegister, Error, UseAuthPasswordRegisterArgs>(
    AuthKeys.PasswordRegister,
    ({ url, profile }) => authPasswordRegister(url, profile)
  );
