export enum BackButtonToActionNames {
  Set = 'SET_BACK_BUTTON_TO',
  Reset = 'RESET_BACK_BUTTON_TO',
}

export type SetBackButtonToAction = {
  type: BackButtonToActionNames;
  payload: BackButtonToInitialState;
};

export type ResetBackButtonToAction = {
  type: BackButtonToActionNames;
  payload: null;
};

export type BackButtonToInitialState = {
  isVisible: boolean;
  isHideMenu: boolean;
  name: string;
  onClick: () => void;
};

export type BackButtonToAction =
  | SetBackButtonToAction
  | ResetBackButtonToAction;
