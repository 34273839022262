import { Profile } from '@keaze/web/common/interfaces';
import {
  ProfileAction,
  ProfileActionNames,
  ProfileInitialState,
} from './profile.types';

export const PROFILE_INITIAL_STATE: ProfileInitialState = {
  profile: {
    id: 0,
    email: null,
    firstName: null,
    lastName: null,
    fullName: null,
    phone: null,
    dateOfBirth: null,
    livingCircumstance: null,
    homeLocalAuthority: null,
    homeAddress: null,
    homePostcode: null,
    workingCircumstance: null,
    workLocalAuthority: null,
    workAddress: null,
    workPostcode: null,
    occupation: null,
    employerName: null,
    annualHouseholdIncome: null,
    deposit: null,
    dependants: null,
    eligibleCitizen: null,
    firstTimeBuyer: null,
    homeOwnershipStatus: null,
    armedForcesMember: null,
    hasDisability: null,
    keyworker: null,
    housingWaitingList: null,
    remarks: null,
    buyingInterestPeriod: null,
    bestTimeToContactYou: [],
    privacyPolicyAccepted: null,
    newsletterConsent: null,
    newsSurveysConsent: null,
    partnersOffersConsent: null,
    smsConsent: null,
    whatsappConsent: null,
    updatedAt: '',
    createdAt: '',
  },
  loading: false,
  error: null,
};

export const profileReducer = (
  state: ProfileInitialState = PROFILE_INITIAL_STATE,
  action: ProfileAction
) => {
  switch (action.type) {
    case ProfileActionNames.Set:
      return {
        ...state,
        profile: {
          ...(action.payload as Profile),
        },
      };

    case ProfileActionNames.Update:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...(action.payload as Profile),
        },
      };

    case ProfileActionNames.Clear:
      return PROFILE_INITIAL_STATE;

    case ProfileActionNames.Loading:
      return {
        ...state,
        loading: action.payload as boolean,
      };

    case ProfileActionNames.Error:
      return {
        ...state,
        error: action.payload as Error,
      };

    default:
      return state;
  }
};
