import { QueryClient, QueryOptions } from 'react-query';

import { isServer } from '@keaze/web/utils/isServer';

const defaultConfig: QueryOptions = {
  cacheTime:
    process.env.NODE_ENV === 'development' || isServer()
      ? Infinity
      : 5 * 60 * 1000,
  retryDelay: (attemptIndex) =>
    process.env.NODE_ENV === 'development'
      ? 0
      : Math.min(1000 * 2 ** attemptIndex, 30000),
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...defaultConfig,
      refetchOnWindowFocus: false,
    },
    mutations: {
      ...defaultConfig,
    },
  },
});
