import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  UTMQueryParams,
  StoredUTMQueryParams,
  LocalStorageKeys,
} from '@keaze/web/common/enums';
import { useLocalStorage } from '@keaze/web/hooks';
import { UTMParams } from './useUTMParams.types';

export const useUTMParams = () => {
  const router = useRouter();
  const utmSourceQueryParam = router.query[UTMQueryParams.UtmSource] as string;
  const utmMediumQueryParam = router.query[UTMQueryParams.UtmMedium] as string;
  const utmCampaignQueryParam = router.query[
    UTMQueryParams.UtmCampaign
  ] as string;
  const utmTermQueryParam = router.query[UTMQueryParams.UtmTerm] as string;
  const utmContentQueryParam = router.query[
    UTMQueryParams.UtmContent
  ] as string;

  const [_, setStoredUTM] = useLocalStorage<UTMParams | null>(
    LocalStorageKeys.UTMParams,
    null
  );

  useEffect(() => {
    if (
      utmSourceQueryParam ||
      utmMediumQueryParam ||
      utmCampaignQueryParam ||
      utmTermQueryParam ||
      utmContentQueryParam
    ) {
      setStoredUTM({
        [StoredUTMQueryParams.UtmSource]: utmSourceQueryParam,
        [StoredUTMQueryParams.UtmMedium]: utmMediumQueryParam,
        [StoredUTMQueryParams.UtmCampaign]: utmCampaignQueryParam,
        [StoredUTMQueryParams.UtmTerm]: utmTermQueryParam,
        [StoredUTMQueryParams.UtmContent]: utmContentQueryParam,
      });
    }
  }, [
    utmSourceQueryParam,
    utmMediumQueryParam,
    utmCampaignQueryParam,
    utmTermQueryParam,
    utmContentQueryParam,
    setStoredUTM,
  ]);
};
