import { useMutation } from 'react-query';

import { Token } from '@keaze/web/common/interfaces';
import {
  getProfile,
  ExpectedGetProfile,
} from '@keaze/web/api/profile/getProfile';

type Args = {
  token: Token;
};

export const useGetProfileMutation = () =>
  useMutation<ExpectedGetProfile, Error, Args>(({ token }) =>
    getProfile(token)
  );
