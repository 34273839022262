import { Passport } from '@keaze/web/common/interfaces';

export type PassportInitialState = {
  passport: Passport;
  loading: boolean;
  error: Error | null;
};

export enum PassportActionNames {
  Set = 'SET_PASSPORT',
  Update = 'UPDATE_PASSPORT',
  Loading = 'LOADING_PASSPORT',
  Error = 'ERROR_PASSPORT',
  Clear = 'CLEAR_PASSPORT',
}

export type SetPassportAction = {
  type: PassportActionNames;
  payload: Passport;
};
export type SetPassport = (passport: Passport) => SetPassportAction;

export type UpdatePassportAction = {
  type: PassportActionNames;
  payload: Passport;
};
export type UpdatePassport = (passport: Passport) => UpdatePassportAction;

export type ClearPassportAction = {
  type: PassportActionNames;
  payload?: null;
};
export type ClearPassport = () => ClearPassportAction;

export type LoadingPassportAction = {
  type: PassportActionNames;
  payload: boolean;
};
export type LoadingPassport = (loading: boolean) => LoadingPassportAction;

export type ErrorPassportAction = {
  type: PassportActionNames;
  payload: Error;
};
export type ErrorPassport = (error: Error) => ErrorPassportAction;

export type PassportAction =
  | SetPassportAction
  | UpdatePassportAction
  | ClearPassportAction
  | LoadingPassportAction
  | ErrorPassportAction;
