import styled from 'styled-components';
import { fade } from '@material-ui/core/styles';
import MUIBackdrop, {
  BackdropProps as MUIBackdropProps,
} from '@material-ui/core/Backdrop';

import { WithMyTheme } from '@keaze/web/theme';

interface BackdropProps extends WithMyTheme {
  $isCardGroup: boolean;
  $position: string;
  $opacity: number;
}

export const Backdrop = styled(MUIBackdrop)<MUIBackdropProps & BackdropProps>`
  ${({
    theme: { palette },
    $isCardGroup,
    $position,
    $opacity,
  }: BackdropProps) => `
    position: ${$position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${fade(palette.background.paper, $opacity)};
    z-index: 999;

    ${
      $isCardGroup
        ? `
            left: -6px;
            width: calc(100% + 12px); 
          `
        : ''
    };
  `}
`;
