export enum OccupancyType {
  Sale = 'for-sale',
  Rent = 'to-rent',
}

export enum PersonalisedSearchFormView {
  empty = 'empty',
  noData = 'noData',
  withPersonalisedSearch = 'withPersonalisedSearch',
  withoutPersonalSearch = 'withoutPersonalSearch',
}

export enum LocalStorageKeys {
  personalizedSearch = '@keaze/personalisedSearch',
  addressResponse = '@keaze/addressResponse',
  workAddressResponse = '@keaze/workAddressResponse',
  searchType = '@keaze/searchType',
  filters = '@keaze/filters',
  sortBy = '@keaze/sortBy',
  UTMParams = '@keaze/UTMParams',
}

export enum CookieKeys {
  Token = 'token',
  RegisterInterest = 'registerInterest',
  Passport = 'passport',
}

export enum AvailabilityStatusCode {
  ComingSoon = 'coming-soon',
  ForSale = 'available',
  Sold = 'closed',
}

export enum QueryModals {
  map = 'map',
  video = 'video',
  plan = 'plan',
}

export enum QueryParams {
  TokenError = 'tokenError',
  Property = 'property',
  Location = 'location',
  OccupancyType = 'type',
  Listing = 'listing',
  ListingSlug = 'slug',
  Radius = 'radius',
  Schemes = 'schemes',
  IgnoreVisibility = 'ignore_visibility',
  ReferredFrom = 'referred_from',
  RegisterInterestFailed = 'registerInterestFailed',
  AuthFailed = 'authFailed',
  Bedrooms = 'bedrooms',
  Topic = 'topic',
  Tag = 'tag',
  Id = 'id',
  PassportError = 'passport_error',
}

export enum PartnersQueryParams {
  Partner = 'partner',
  Tenures = 'tenures',
  Source = 'source',
}

export enum UTMQueryParams {
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmTerm = 'utm_term',
  UtmContent = 'utm_content',
}

export enum StoredUTMQueryParams {
  UtmSource = 'utmSource',
  UtmMedium = 'utmMedium',
  UtmCampaign = 'utmCampaign',
  UtmTerm = 'utmTerm',
  UtmContent = 'utmContent',
}

export enum EligibilityStatus {
  Passed = 'passed',
  Considered = 'considered',
  Incomplete = 'incomplete',
  Failed = 'failed',
}

export enum DeviceType {
  Console = 'console',
  Mobile = 'mobile',
  Tablet = 'tablet',
  Smarttv = 'smarttv',
  Wearable = 'wearable',
  Embedded = 'embedded',
  Desktop = 'desktop',
}

export enum EnumSearchBasicParams {
  OccupancyType = 'type',
  Location = 'location',
  Radius = 'radius',
}

export enum SearchParamsForListing {
  Bedrooms = 'bedrooms',
}

export enum ContentPageTemplates {
  Basic = 'basic',
  FeaturedListings = 'featured-listings',
}

export enum Schemes {
  SharedOwnership = 'shared-ownership',
}

export enum DisplayAs {
  Featured = 'featured',
  Premium = 'premium',
  Regular = 'regular',
}
