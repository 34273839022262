import { useCallback } from 'react';

import { useWindowLocation } from '@keaze/web/hooks/useWindowLocation.hook';
import {
  useAuthPasswordAccess,
  useAuthPasswordRegister,
} from '@keaze/web/hooks/queries/auth';
import {
  SendMagicLinkAccess,
  SendMagicLinkRegister,
} from './useMagicLink.types';

type UseMagicLinkExpected = {
  isLoading: boolean;
  isSuccess: boolean;
  sendMagicLinkAccess: SendMagicLinkAccess;
  sendMagicLinkRegister: SendMagicLinkRegister;
  resetAuthPasswordAccess: () => void;
  resetAuthPasswordRegister: () => void;
};

type UseMagicLink = (urlAfterSignIn?: string) => UseMagicLinkExpected;

export const useMagicLink: UseMagicLink = (urlAfterSignIn) => {
  const location = useWindowLocation();
  const {
    mutate: mutateAuthPasswordAccess,
    isLoading: isAuthPasswordAccessLoading,
    isSuccess: isAuthPasswordAccessSuccess,
    reset: resetAuthPasswordAccess,
  } = useAuthPasswordAccess();
  const {
    mutate: mutateAuthPasswordRegister,
    isLoading: isAuthPasswordRegisterLoading,
    isSuccess: isAuthPasswordRegisterSuccess,
    reset: resetAuthPasswordRegister,
  } = useAuthPasswordRegister();

  const generateUrl = useCallback(() => {
    if (location) {
      const { origin, pathname, search } = location;
      const defaultPath = `${origin}/auth`;

      return `${defaultPath}${pathname}${search ?? ''}`;
    }

    return '';
  }, [location]);

  const sendMagicLinkAccess: SendMagicLinkAccess = useCallback(
    (email) => {
      mutateAuthPasswordAccess({
        email,
        url: urlAfterSignIn ?? generateUrl(),
      });
    },
    [urlAfterSignIn, mutateAuthPasswordAccess, generateUrl]
  );

  const sendMagicLinkRegister: SendMagicLinkRegister = useCallback(
    (profile) => {
      mutateAuthPasswordRegister({
        url: generateUrl(),
        profile,
      });
    },
    [mutateAuthPasswordRegister, generateUrl]
  );

  return {
    isLoading: isAuthPasswordAccessLoading || isAuthPasswordRegisterLoading,
    isSuccess: isAuthPasswordAccessSuccess || isAuthPasswordRegisterSuccess,
    sendMagicLinkAccess,
    sendMagicLinkRegister,
    resetAuthPasswordAccess,
    resetAuthPasswordRegister,
  };
};
