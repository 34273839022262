import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import MUIList from '@material-ui/core/List';
import MUIListItem, { ListItemTypeMap } from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const Links = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
`;

export const List = styled(MUIList)`
  display: flex;
  margin-right: 23px;
`;

export const ListItem: OverridableComponent<
  ListItemTypeMap<{ button?: false }, 'li'>
> &
  ExtendButtonBase<ListItemTypeMap<{ button: true }, 'div'>> = styled(
  MUIListItem
)`
  ${({
    theme: {
      transitions: { duration, easing },
      colors,
      palette,
    },
  }) => `
    position: relative;
    width: auto;
    padding: 0;
    margin-right: 40px;
    font-weight: 600;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    & > a {
      color: ${palette.primary.main};
      transition: ${duration.shorter}ms ${easing.easeInOut};
    }

    &:hover,
    &:focus {
      background-color: transparent;

      & > a {
        color: ${palette.action.hover};
      }
    }

    &.Mui-selected {
      background-color: transparent;

      &:before {
        content: '';
        position: absolute;
        height: 2px;
        left: 0;
        right: 0;
        top: -45px;
        background-color: ${palette.secondary.main};
      }

      & > a {
        color: ${palette.secondary.main};
      }

      &:hover,
      &:focus {
        background-color: transparent;

        & > a {
          color: ${palette.secondary.main};
        }
      }
    }
  `}
`;

export const SignOutButton = styled(Button)`
  ${({ theme: { palette } }) => `
    color: ${palette.primary.main};
    background-color: ${palette.common.white};
    margin-left: 17px;
  `}
`;

export const SignInButton = styled(Button)`
  ${({ theme: { palette } }) => `
    color: ${palette.primary.main};
    background-color: transparent;
    margin-right: 23px;
  `}
`;
