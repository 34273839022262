import React, { FC } from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components';
import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';

import { theme } from './theme';
import { ThemeProviderProps } from './theme.types';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    background-color: #E5E5E5;
  }

  * {
    box-sizing: border-box;
  }

  a {
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
`;

export const ThemeProvider: FC<ThemeProviderProps> = ({
  deviceType,
  children,
}) => (
  <MaterialThemeProvider theme={theme(deviceType)}>
    <StylesProvider injectFirst>
      <StyledThemeProvider theme={theme(deviceType)}>
        <GlobalStyle />
        {children}
      </StyledThemeProvider>
    </StylesProvider>
  </MaterialThemeProvider>
);
