import { request } from '../../api';
import { PROFILE_QUERY } from '../profile';
import { GetProfile } from './getProfile.types';

export const getProfile: GetProfile = async (token) =>
  await request({
    query: PROFILE_QUERY,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
