import React from 'react';
import { AppContext, AppProps } from 'next/app';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { UAParser } from 'ua-parser-js';

import { Partner } from '@keaze/web/components/partner';
import { queryClient } from '@keaze/web/libs/queryClient';
import { BaseLayout } from '../layouts/base';
import {
  useUTMParams,
  //   useRouteChange,
  useServerSideInjected,
} from '@keaze/web/hooks';
import { ThemeProvider } from '@keaze/web/theme';
import { Err } from './_error';
import { DeviceType } from '../common/enums';
import { StoreProvider } from '../store';
import { AuthProvider } from '../contexts/auth';

type CustomProps = {
  err: Err;
  deviceType: string;
};

type Props = CustomProps & AppProps;

const App = ({
  Component,
  pageProps,
  router: { route },
  err,
  deviceType,
}: Props) => {
  useServerSideInjected();
  useUTMParams();
  //   useRouteChange();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <ThemeProvider deviceType={deviceType}>
            <AuthProvider>
              <Partner />
              <BaseLayout>
                <Component {...pageProps} key={route} err={err} />
              </BaseLayout>
            </AuthProvider>
          </ThemeProvider>
        </StoreProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </>
  );
};

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {};

  const device = new UAParser(
    ctx.req ? ctx.req.headers['user-agent'] : ''
  ).getDevice();
  const deviceType = device.type || DeviceType.Mobile;

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    pageProps,
    deviceType,
  };
};

export default App;
