import { useState, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';

import { AuthContext } from '@keaze/web/contexts/auth';
import { Link as LinkType } from '../base.types';
import { QueryParams, OccupancyType } from '@keaze/web/common/enums';
import {
  getPartnersParams,
  getSearchBasicParams,
} from '@keaze/web/utils/params';

export const useHeader = () => {
  const router = useRouter();
  const { isAuthenticated } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const partnerParams = getPartnersParams(router.query);
  const { type, ...searchBasicParams } = getSearchBasicParams(router.query);

  const unauthorizedLinks: LinkType[] = useMemo(
    () => [
      {
        name: 'For sale',
        href: {
          pathname: '/search',
          query: {
            ...searchBasicParams,
            ...partnerParams,
            [QueryParams.OccupancyType]: OccupancyType.Sale,
          },
        },
      },
      {
        name: 'To rent',
        href: {
          pathname: '/search',
          query: {
            ...searchBasicParams,
            [QueryParams.OccupancyType]: OccupancyType.Rent,
          },
        },
      },
      // {
      //   name: 'Blog',
      //   href: '/blog',
      // },
      {
        name: 'Keaze for Business',
        href: 'https://partners.keaze.com',
        isExternal: true,
      },
    ],
    [searchBasicParams, partnerParams]
  );

  const authorizeLinks: LinkType[] = useMemo(
    () => [
      {
        name: 'For sale',
        href: {
          pathname: '/search',
          query: {
            ...searchBasicParams,
            ...partnerParams,
            [QueryParams.OccupancyType]: OccupancyType.Sale,
          },
        },
      },
      {
        name: 'To rent',
        href: {
          pathname: '/search',
          query: {
            ...searchBasicParams,
            [QueryParams.OccupancyType]: OccupancyType.Rent,
          },
        },
      },
      // {
      //   name: 'Blog',
      //   href: '/blog',
      // },
      {
        name: 'Keaze for Business',
        href: 'https://partners.keaze.com',
        isExternal: true,
      },
      {
        name: 'Dashboard',
        href: '/dashboard',
      },
    ],
    [searchBasicParams, partnerParams]
  );

  const toggleDrawer = () => setDrawerOpen((prevState) => !prevState);

  return {
    links: isAuthenticated ? authorizeLinks : unauthorizedLinks,
    drawerOpen,
    toggleDrawer,
  };
};
