import { useMutation } from 'react-query';

import {
  authPasswordAccess,
  ExpectedAuthPasswordAccess,
} from '@keaze/web/api/auth/authPasswordAccess';
import { AuthKeys } from '@keaze/web/api/auth/auth.types';

type UseAuthPasswordAccessArgs = {
  email: string;
  url: string;
};

export const useAuthPasswordAccess = () =>
  useMutation<ExpectedAuthPasswordAccess, Error, UseAuthPasswordAccessArgs>(
    AuthKeys.PasswordAccess,
    ({ email, url }) => authPasswordAccess(email, url)
  );
