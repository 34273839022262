import {
  ABTestActionNames,
  ABTestAction,
  ABTestInitialState,
} from './abTest.types';

export const AB_TEST_INITIAL_STATE: ABTestInitialState = {
  personalizedSearch: {
    formCallToAction: null,
  },
};

export const abTestReducer = (
  state: ABTestInitialState = AB_TEST_INITIAL_STATE,
  action: ABTestAction
) => {
  switch (action.type) {
    case ABTestActionNames.SetPersonalizedSearch:
      return {
        ...state,
        personalizedSearch: {
          formCallToAction: action.payload,
        },
      };

    default:
      return state;
  }
};
