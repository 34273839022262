import { useCallback, useState } from 'react';

import { isServer } from '../utils/isServer';

export const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (state: T) => void, () => void] => {
  const getValue = useCallback(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);

      return initialValue;
    }
  }, [key, initialValue]);

  const [storedValue, setStoredValue] = useState<T>(getValue());

  const setValue = useCallback(
    (value: T) => {
      if (!isServer()) {
        try {
          setStoredValue(value);

          window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
          console.warn(`Error setting localStorage key “${key}”:`, error);
        }
      }
    },
    [key]
  );

  const removeValue = useCallback(() => {
    if (!isServer()) {
      try {
        window.localStorage.removeItem(key);
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    }
  }, [key]);

  return [storedValue, setValue, removeValue];
};
