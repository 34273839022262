import { useContext, useEffect } from 'react';

import {
  StoreContext,
  setProfile,
  loadingProfile,
  errorProfile,
} from '@keaze/web/store';
import { useGetProfileMutation } from '@keaze/web/hooks/queries/profile/useGetProfile';
import { Token } from '@keaze/web/common/interfaces';

type UseProfile = (isAuthenticated: boolean, token: Token) => void;

export const useProfile: UseProfile = (isAuthenticated, token) => {
  const { dispatch } = useContext(StoreContext);
  const { data, isLoading, isIdle, error, mutate } = useGetProfileMutation();

  useEffect(() => {
    if (isAuthenticated) {
      mutate({ token });
    }
  }, [token, isAuthenticated, mutate]);

  useEffect(() => {
    if (data) {
      dispatch(setProfile(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (!isIdle) {
      dispatch(loadingProfile(isLoading));
    }
  }, [isIdle, isLoading, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(errorProfile(error));
    }
  }, [error, dispatch]);
};
