import { PartnersQueryParams } from '@keaze/web/common/enums';
import { SearchPartnerParams } from '@keaze/web/common/interfaces';
import { GetPartnersParams } from './getPartnersParams.types';

type GenerateParam = {
  name: PartnersQueryParams;
  value: string | number;
};

type GenerateParams = (params: GenerateParam[]) => SearchPartnerParams;

const generateParams: GenerateParams = (params) =>
  params.reduce((acc, { name, value }) => {
    if (value) {
      return {
        ...acc,
        [name]: value,
      };
    }

    return acc;
  }, {} as SearchPartnerParams);

export const getPartnersParams: GetPartnersParams = (query) => {
  const partner = query[PartnersQueryParams.Partner] as string;
  const tenures = query[PartnersQueryParams.Tenures] as string;

  return generateParams([
    { name: PartnersQueryParams.Source, value: partner },
    { name: PartnersQueryParams.Tenures, value: Number(tenures) },
  ]);
};
