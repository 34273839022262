import React, {
  createContext,
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { GTMCreateAccount } from '@keaze/web/libs/GTM';
import { AuthData } from '@keaze/web/common/interfaces';
import {
  useAuth,
  useGTMLogin,
  useProfile,
  DEFAULT_AUTH_DATA,
} from '@keaze/web/hooks';
import { ToggleAuthDialog } from './auth.types';
import {
  ExpectedUseSignInDialog,
  useSignInDialog,
} from '@keaze/web/components/auth/hooks';

interface Context extends AuthData, ExpectedUseSignInDialog {
  openAuthDialog: boolean;
  logout: () => void;
  toggleAuthDialog: ToggleAuthDialog;
}

export const AuthContext = createContext<Context>({
  ...DEFAULT_AUTH_DATA,
  isSignInDialog: false,
  openAuthDialog: false,
  logout: () => {},
  toggleAuthDialog: () => {},
  setIsSignInDialog: () => {},
  resetSignInDialog: () => {},
  toggleSignInDialog: () => {},
});

export const AuthProvider: FC = memo(({ children }) => {
  const { authData, logout } = useAuth();
  const [openAuthDialog, setOpenAuthDialog] = useState<boolean>(false);
  const {
    isSignInDialog,
    setIsSignInDialog,
    resetSignInDialog,
    toggleSignInDialog,
  } = useSignInDialog();

  useGTMLogin(authData.isAuthenticated);
  useProfile(authData.isAuthenticated, authData.token);

  useEffect(() => {
    if (authData.isFailed) {
      setOpenAuthDialog(true);
    }
  }, [authData]);

  const toggleAuthDialog: ToggleAuthDialog = useCallback(
    (data) => {
      if (data) {
        if (data.triggeredBy) {
          GTMCreateAccount(data.triggeredBy);
        }

        if (data.isSignInDialog) {
          setIsSignInDialog(data.isSignInDialog);
        }
      }

      setOpenAuthDialog((prevState) => !prevState);
    },
    [setIsSignInDialog]
  );

  return (
    <AuthContext.Provider
      value={{
        ...authData,
        isSignInDialog,
        openAuthDialog,
        logout,
        toggleAuthDialog,
        setIsSignInDialog,
        resetSignInDialog,
        toggleSignInDialog,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});
