import {
  SetProfile,
  UpdateProfile,
  ClearProfile,
  LoadingProfile,
  ProfileActionNames,
  ErrorProfile,
} from './profile.types';

export const setProfile: SetProfile = (profile) => ({
  type: ProfileActionNames.Set,
  payload: profile,
});

export const updateProfile: UpdateProfile = (profile) => ({
  type: ProfileActionNames.Update,
  payload: profile,
});

export const clearProfile: ClearProfile = () => ({
  type: ProfileActionNames.Clear,
});

export const loadingProfile: LoadingProfile = (loading) => ({
  type: ProfileActionNames.Loading,
  payload: loading,
});

export const errorProfile: ErrorProfile = (error) => ({
  type: ProfileActionNames.Error,
  payload: error,
});
