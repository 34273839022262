import { SearchParamsForListing } from '@keaze/web/common/enums';
import { ExpectedSearchParamsForListing } from '@keaze/web/common/interfaces';
import { GetSearchParamsForListing } from './getSearchParamsForListing.types';

type GenerateParam = {
  name: SearchParamsForListing;
  value?: string | string[];
};

type GenerateParams = (
  params: GenerateParam[]
) => ExpectedSearchParamsForListing;

const generateParams: GenerateParams = (params) =>
  params.reduce((acc, { name, value }) => {
    if (value) {
      if (Array.isArray(value)) {
        return {
          ...acc,
          [name]: value.map((bedroom) => Number(bedroom)),
        };
      }

      return {
        ...acc,
        [name]: [Number(value)],
      };
    }

    return acc;
  }, {} as ExpectedSearchParamsForListing);

export const getSearchParamsForListing: GetSearchParamsForListing = (query) => {
  const bedrooms = query[SearchParamsForListing.Bedrooms];

  return generateParams([
    {
      name: SearchParamsForListing.Bedrooms,
      value: bedrooms,
    },
  ]);
};
