import { createMuiTheme, Theme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import mediaQuery from 'css-mediaquery';

import { DeviceType } from '../common/enums';
import { Colors, CutCorner, Sizes } from './theme.types';

export interface MyTheme extends Theme {
  colors: Colors;
  cutCorner: CutCorner;
  sizes: Sizes;
}

export interface WithMyTheme {
  theme: MyTheme;
}

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: Colors;
    cutCorner: CutCorner;
    sizes: Sizes;
  }

  interface ThemeOptions {
    colors?: Colors;
    cutCorner?: CutCorner;
    sizes?: Sizes;
  }
}

const COLORS: Colors = {
  greys: {
    greys0: '#F5F4F1',
    greys1: '#DCE0E5',
    greys2: '#F9F8F6',
    greys3: '#F3F3F3',
    greys4: '#E2E2E2',
    greys5: '#F1F3F5',
    greys6: '#555555',
    greys7: '#747474',
    greys8: '#979797',
    greys9: '#777777',
    greys10: '#7F7F7F',
    greys11: '#C1C1C1',
    greys12: '#787878',
    greys13: '#6C6C6B',
    greys14: '#BDBCBB',
    greys15: '#EBEBEB',
    greys16: '#E5E7EA',
    greys17: '#ACACAC',
    greys18: '#959493',
    greys19: '#BCC4CE',
    greys20: '#E9E9E7',
    greys21: '#5B5B5B',
    greys22: '#1D1D1D',
    greys23: '#E3E5E8',
    greys24: '#737373',
    greys25: '#696969',
  },
  reds: {
    reds0: '#EC6779',
    reds1: '#FFEFF1',
  },
  blues: {
    blues0: '#465BCB',
    blues1: '#203B5C',
    blues2: '#4297FF',
    blues3: '#3A6BA6',
    blues4: '#323941',
  },
  greens: {
    green0: '#29908F',
    green1: '#0A856C',
    green2: '#006651',
  },
};

const CUT_CORNER: CutCorner = {
  topLeft: {
    big: 'polygon(37px 0, 100% 0, 100% 100%, 0 100%, 0 37px)',
    small: 'polygon(23px 0, 100% 0, 100% 100%, 0 100%, 0 23px)',
  },
  topRight: {
    big: 'polygon(0 0, calc(100% - 37px) 0, 100% 37px, 100% 100%, 0 100%, 0 0)',
    small:
      'polygon(0 0, calc(100% - 23px) 0, 100% 23px, 100% 100%, 0 100%, 0 0)',
  },
};

const SIZES: Sizes = {
  headerHeight: {
    default: '100px',
    sm: '80px',
  },
};

export const theme = (deviceType: string): MyTheme =>
  createMuiTheme({
    props: {
      MuiUseMediaQuery: {
        ssrMatchMedia: (query) => ({
          matches: mediaQuery.match(query, {
            width: deviceType === DeviceType.Mobile ? 0 : 1200,
          }),
        }),
      },
    },
    palette: createPalette({
      primary: {
        dark: '#044391',
        main: '#1F3B5D',
        light: 'rgba(32,59,92,0.2)',
        contrastText: '#fff',
      },
      secondary: {
        dark: '#D43754',
        main: '#EC6779',
        light: 'rgba(236,103,121,0.25)',
        contrastText: '#fff',
      },
      error: {
        light: '#FFEFF1',
        main: '#FF2E4A',
        contrastText: '#fff',
      },
      text: {
        secondary: '#555555',
        primary: '#303030',
        disabled: '#555555',
        hint: '#303030',
      },
      grey: {
        '300': '#F9F8F6',
        A100: '#EAECEE',
        A200: '#EAECEE',
      },
      action: {
        hover: '#044390',
      },
    }),
    typography: {
      fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: 14,
      htmlFontSize: 16,

      h1: {
        color: '#303030',
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '1.875rem',
          lineHeight: '2.25rem',
        },
      },
      h2: {
        color: '#303030',
        fontSize: '1.375rem',
        lineHeight: '1.875rem',
        fontWeight: 700,
        '@media (min-width:600px)': {
          fontSize: '1.5rem',
        },
      },
      h3: {
        color: '#303030',
        fontSize: '1.125rem',
        lineHeight: '1.375rem',
        fontWeight: 700,
      },
      body1: {
        color: '#303030',
        fontSize: '1rem',
        lineHeight: '1.625rem',
        fontWeight: 400,
      },
      body2: {
        color: '#303030',
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        fontWeight: 500,
      },
      caption: {
        color: '#303030',
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
        fontWeight: 500,
      },
      button: {
        fontSize: '1rem',
        lineHeight: '1.625rem',
        fontWeight: 600,
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: 0,

        '&:hover': {
          boxShadow: 'none',
        },

        '&.MuiButton-text': {
          borderRadius: 0,
        },

        '&.MuiButton-contained': {
          boxShadow: 'none',
          padding: '11px 30px',
          borderRadius: 0,

          '&:hover': {
            boxShadow: 'none',
          },
        },

        '&.MuiButton-containedSizeSmall': {
          fontSize: '1rem',
          padding: '11px 17px',
        },

        '&.MuiButton-containedSizeLarge': {
          fontSize: '1rem',
          padding: '11px 50px',
        },

        '&.Mui-disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    colors: COLORS,
    cutCorner: CUT_CORNER,
    sizes: SIZES,
  });
