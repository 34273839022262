import React, { createContext, useReducer, FC } from 'react';

import {
  BACK_BUTTON_TO_INITIAL_STATE,
  BackButtonToAction,
  backButtonToReducer,
} from './backButtonTo';
import {
  PROFILE_INITIAL_STATE,
  ProfileAction,
  profileReducer,
} from './profile';
import {
  START_INTEREST_REGISTER_INITIAL_STATE,
  StartInterestRegisterAction,
  startInterestRegisterReducer,
} from './startInterestRegister';
import { AB_TEST_INITIAL_STATE, ABTestAction, abTestReducer } from './abTest';
import { StoreAction, StoreReducer, StoreContextValue } from './store.types';

const INITIAL_STATE: StoreReducer = {
  backButtonTo: BACK_BUTTON_TO_INITIAL_STATE,
  profile: PROFILE_INITIAL_STATE,
  startInterestRegister: START_INTEREST_REGISTER_INITIAL_STATE,
  abTest: AB_TEST_INITIAL_STATE,
};

type MainReducer = (reducer: StoreReducer, action: StoreAction) => StoreReducer;

const mainReducer: MainReducer = (
  { backButtonTo, profile, startInterestRegister, abTest },
  action: StoreAction
) => ({
  backButtonTo: backButtonToReducer(backButtonTo, action as BackButtonToAction),
  profile: profileReducer(profile, action as ProfileAction),
  startInterestRegister: startInterestRegisterReducer(
    startInterestRegister,
    action as StartInterestRegisterAction
  ),
  abTest: abTestReducer(abTest, action as ABTestAction),
});

export const StoreContext = createContext<StoreContextValue>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const StoreProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, INITIAL_STATE);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};
