import { Scheme } from '../common/interfaces';
import { isProduction } from '../utils/isProduction';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface CommonArgs {
  listingSchemes: Scheme[];
  listingSlug: string;
  householdIncome?: number | null;
  estimatedDeposit?: number | null;
}

interface GTMInterestRegistrationArgs extends CommonArgs {
  event:
    | 'interest_registration'
    | 'start_interest_registration'
    | 'duplicated_interest_registration'
    | 'unauthorized_register_interest'
    | 'submit_first_form_interest_registration'
    | 'submit_second_form_interest_registration'
    | 'skip_second_form_interest_registration';
  propertyId: number | null;
  location?: string | null;
  email?: string | null;
}

interface GTMViewListingArgs extends CommonArgs {
  event:
    | 'view_listing'
    | 'view_listing_map'
    | 'view_listing_media'
    | 'view_listing_filter_bedrooms'
    | 'view_listing_property_details';
  bedroomsFilter?: number[] | null;
}

type GTMAddedToFavoritesArgs = CommonArgs;

type GTMDeletedFromFavoritesArgs = CommonArgs;

type GeneratePersonalizedSearchDataExpected = {
  householdIncome: number | null;
  estimatedDeposit: number | null;
};

type GeneratePersonalizedSearchData = (
  householdIncome: number | null,
  estimatedDeposit: number | null
) => GeneratePersonalizedSearchDataExpected;

const generatePersonalizedSearchData: GeneratePersonalizedSearchData = (
  householdIncome,
  estimatedDeposit
) => ({
  householdIncome: householdIncome ? Number(householdIncome) : null,
  estimatedDeposit: estimatedDeposit ? Number(estimatedDeposit) : null,
});

export const GTMPageView = (url: string) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'page_view',
        page: url,
      });
  }
};

export const GTMLogin = (email: string) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'login',
        loginMethod: email,
      });
  }
};

export const GTMSignUp = (
  contactPreferences: Record<string, boolean | null> | null
) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'sign_up',
        signUpMethod: 'Keaze',
        contactPreferences,
      });
  }
};

export const GTMCheckboxesAtSignUp = (checkboxes: Record<string, boolean>) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'checkboxes_at_sign_up',
        checkboxes,
      });
  }
};

// TODO: fix any type
export const GTMSearch = (filters: any) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'search',
        searchTerm: filters,
        _clear: true,
      });
  }
};

export const GTMInterestRegistration = ({
  event,
  listingSchemes,
  listingSlug,
  propertyId,
  location = null,
  householdIncome = null,
  estimatedDeposit = null,
  email = null,
}: GTMInterestRegistrationArgs) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event,
        listingSchemes,
        listingSlug,
        propertyId,
        location,
        email,
        ...generatePersonalizedSearchData(householdIncome, estimatedDeposit),
      });
  }
};

export const GTMAddedToFavorites = ({
  listingSchemes,
  listingSlug,
  householdIncome = null,
  estimatedDeposit = null,
}: GTMAddedToFavoritesArgs) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'added_to_favorites',
        listingSchemes,
        listingSlug,
        ...generatePersonalizedSearchData(householdIncome, estimatedDeposit),
        _clear: true,
      });
  }
};

export const GTMDeletedFromFavorites = ({
  listingSchemes,
  listingSlug,
  householdIncome = null,
  estimatedDeposit = null,
}: GTMDeletedFromFavoritesArgs) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'deleted_from_favorites',
        listingSchemes,
        listingSlug,
        ...generatePersonalizedSearchData(householdIncome, estimatedDeposit),
        _clear: true,
      });
  }
};

export const GTMViewListing = ({
  event,
  listingSchemes,
  listingSlug,
  bedroomsFilter = null,
  householdIncome = null,
  estimatedDeposit = null,
}: GTMViewListingArgs) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event,
        listingSchemes,
        listingSlug,
        bedroomsFilter,
        ...generatePersonalizedSearchData(householdIncome, estimatedDeposit),
      });
  }
};

export const GTMabTestPersonalizedSearch = (
  event:
    | 'view_eligibility_params_form'
    | 'complete_eligibility_params_form'
    | 'eligibility_form_cta_defined',
  value: 'banner' | 'button' | 'landing' | null
) => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event,
        formCallToAction: value,
      });
  }
};

export const GTMCreateAccount = (triggeredBy: 'register' | 'favorite') => {
  if (isProduction()) {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: 'view_create_account_form',
        triggered_by: triggeredBy,
      });
  }
};
