import React, { FC, memo } from 'react';
import dynamic from 'next/dynamic';
import Container from '@material-ui/core/Container';

import { config } from '@keaze/web/config';
import { ProgressBarProps } from '@keaze/web/components/progressBar';
import { Base, Main } from './base.styles';
import { Header } from './header';

const DynamicFooter = dynamic<unknown>(() =>
  import('./footer').then((model) => model.Footer)
);

const DynamicAuth = dynamic<unknown>(
  () => import('@keaze/web/components/auth').then((model) => model.Auth),
  {
    ssr: false,
  }
);

const DynamicProgressBar = dynamic<ProgressBarProps>(
  () =>
    import('@keaze/web/components/progressBar').then(
      (model) => model.ProgressBar
    ),
  {
    ssr: false,
  }
);

export const BaseLayout: FC = memo(({ children }) => (
  <Base>
    <Header />
    <Main component="main">
      <Container>
        <>{children}</>
      </Container>
    </Main>
    <DynamicFooter />
    <DynamicAuth />
    <DynamicProgressBar />
    <div id={config.QCChoiceMSGId} />
  </Base>
));
