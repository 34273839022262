import {
  StartInterestRegisterActionNames,
  StartInterestRegisterAction,
  StartInterestRegisterInitialState,
} from './startInterestRegister.types';

export const START_INTEREST_REGISTER_INITIAL_STATE: StartInterestRegisterInitialState = {
  name: '',
  address: '',
  schemes: [],
  slug: null,
  propertyId: null,
};

export const startInterestRegisterReducer = (
  state: StartInterestRegisterInitialState = START_INTEREST_REGISTER_INITIAL_STATE,
  action: StartInterestRegisterAction
) => {
  switch (action.type) {
    case StartInterestRegisterActionNames.Set:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
