import { useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { AuthData } from '@keaze/web/common/interfaces';
import { StoreContext, clearProfile } from '@keaze/web/store';

export const DEFAULT_AUTH_DATA: AuthData = {
  token: null,
  isFailed: false,
  isAuthenticated: false,
  isLoading: true,
};

type ExpectedUseAuth = {
  authData: AuthData;
  logout: () => Promise<void>;
};

type UseAuth = () => ExpectedUseAuth;

export const useAuth: UseAuth = () => {
  const router = useRouter();
  const [authData, setAuthData] = useState<AuthData>(DEFAULT_AUTH_DATA);
  const { dispatch } = useContext(StoreContext);

  useEffect(() => {
    const initializeAuth = async () => {
      const response = await fetch('/api/checkAuth');
      const data: AuthData = await response.json();

      setAuthData(data);
    };

    initializeAuth();
  }, []);

  const logout = useCallback(async () => {
    await fetch('/api/logout');

    dispatch(clearProfile());
    setAuthData({ ...DEFAULT_AUTH_DATA, isLoading: false });

    router.push('/');
  }, [router, dispatch]);

  return {
    authData,
    logout,
  };
};
