import { NextRouter } from 'next/router';

type DeleteParamFromQueryByNameArgs = {
  name: string;
  router: NextRouter;
  withShallow?: boolean;
};

type DeleteParamFromQueryByName = (
  args: DeleteParamFromQueryByNameArgs
) => void;

export const deleteParamFromQueryByName: DeleteParamFromQueryByName = ({
  name,
  router,
  withShallow = false,
}) => {
  const query = { ...router.query };

  delete query[name];
  router.replace({ query }, undefined, { shallow: withShallow });
};
